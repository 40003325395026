import React, { FC, MouseEvent, useState } from 'react'
import cn from 'classnames'
import { useUI } from '@components/ui'
import { FilledHeart, Heart } from '@components/icons'
import { FilledHeartSecond, HeartSecond } from '@components/iconsSetTwo'
import useAddItem from '@framework/wishlist/use-add-item'
import useCustomer from '@framework/customer/use-customer'
import useWishlist from '@framework/wishlist/use-wishlist'
import useRemoveItem from '@framework/wishlist/use-remove-item'
import type { Product, ProductVariant } from '@commerce/types/product'
import { MODAL_VIEWS } from 'constants/common'
import { FilledHeartThird, HeartThird } from '@components/iconsSetThree'
import { Icon } from '@components/common'
import {
  HeartFilledIconNinelife,
  HeartIconNinelife,
} from '@components/iconSetNinelife'
import { HeartFilledNinefit } from '@components/iconSetNinefit/HeartFilledNinefit'
import { HeartNinefit } from '@components/iconSetNinefit/HeartNinefit'
import { HeartIconCopperStudio } from '@components/iconSetCopperStudio/HeartIconCopperStudio'
import { HeartFilledIconCopperStudio } from '@components/iconSetCopperStudio/HeartFilledIconCopperStudio'
import s from './WishlistButton.module.css'

type Props = {
  isConfirmationRequired?: boolean
  productId: Product['id']
  variant: Pick<ProductVariant, 'id'>
  onAddToWishlist?: () => unknown
} & React.ButtonHTMLAttributes<HTMLButtonElement>

const WishlistButton: FC<Props> = ({
  productId,
  variant,
  className,
  isConfirmationRequired,
  onAddToWishlist = () => {},
  ...props
}) => {
  const { data } = useWishlist()
  const addItem = useAddItem()
  const removeItem = useRemoveItem()
  const { data: customer } = useCustomer()
  const { openModal, setModalView, closeModal } = useUI()
  const [loading, setLoading] = useState(false)

  // @ts-ignore Wishlist is not always enabled
  const itemInWishlist = data?.items?.find(
    // @ts-ignore Wishlist is not always enabled
    item => item.product_id === +productId && item.variant_id === +variant.id,
  )

  const handleRemove = async () => {
    closeModal()
    setLoading(true)
    try {
      await removeItem({ id: itemInWishlist.id })
    } finally {
      setLoading(false)
    }
  }

  const confirmRemoveWishlist = () => {
    setModalView(MODAL_VIEWS.confirmRemoveWishlist, {
      hideCrossIcon: true,
      maxWidth: 'medium',
      handleRemove,
    })
    openModal()
  }

  const handleWishlistChange = async (e: MouseEvent) => {
    e.preventDefault()

    if (loading) return

    // A login is required before adding an item to the wishlist
    if (!customer) {
      setModalView(MODAL_VIEWS.login, { maxWidth: 'small' })

      return openModal()
    }

    try {
      if (itemInWishlist) {
        if (isConfirmationRequired) {
          confirmRemoveWishlist()

          return
        }
        setLoading(true)
        await removeItem({ id: itemInWishlist.id! })
      } else {
        setLoading(true)
        await addItem({
          productId,
          variantId: variant?.id || '',
        })
        onAddToWishlist()
      }

      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  const heartClassName = cn(s.icon, { [s.loading]: loading })
  const filledHeartClassName = cn(s.icon, 'text-brand', {
    [s.loading]: loading,
  })

  return (
    <button
      type='button'
      aria-label='Add to wishlist'
      className={cn(s.root, className)}
      onClick={handleWishlistChange}
      {...props}
    >
      {itemInWishlist ? (
        <Icon
          set1={<FilledHeart className={filledHeartClassName} />}
          set2={<FilledHeartSecond className={filledHeartClassName} />}
          set3={<FilledHeartThird className={filledHeartClassName} />}
          setNinelife={
            <HeartFilledIconNinelife className={filledHeartClassName} />
          }
          setNinefit={<HeartFilledNinefit className={filledHeartClassName} />}
          setCopperStudio={
            <HeartFilledIconCopperStudio className={heartClassName} />
          }
        />
      ) : (
        <Icon
          set1={<Heart className={heartClassName} />}
          set2={<HeartSecond className={heartClassName} />}
          set3={<HeartThird className={heartClassName} />}
          setNinelife={<HeartIconNinelife className={heartClassName} />}
          setNinefit={<HeartNinefit className={heartClassName} />}
          setCopperStudio={<HeartIconCopperStudio className={heartClassName} />}
        />
      )}
    </button>
  )
}

export default WishlistButton
